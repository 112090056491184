import React, { useMemo } from 'react'
import {
  Box,
  Chip,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { useController } from 'react-hook-form'
import useStyles from './styles'

const CheckedMultiSelect = ({ control, name, label, options, defaultValue = [], ...props }) => {
  const styles = useStyles()
  const {
    field: { value = [], onChange },
    fieldState: { error } = {},
  } = useController({ name, control, defaultValue })

  const selectedValues = useMemo(
    () => (Array.isArray(value) ? value.map(v => v?.value || v) : []),
    [value]
  )

  const handleChange = event => {
    onChange(options.filter(option => event.target.value.includes(option.value)))
  }

  const handleDelete = valueToDelete => {
    onChange(value.filter(v => v?.value !== valueToDelete))
  }

  return (
    <FormControl fullWidth error={!!error} {...props}>
      <InputLabel shrink className={styles.labelInput}>
        {label}
      </InputLabel>
      <Select
        multiple
        label={label}
        value={selectedValues}
        onChange={handleChange}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {selected.map(val => (
              <Chip
                key={val}
                label={options.find(o => o.value === val)?.label || val}
                onDelete={() => handleDelete(val)}
                onMouseDown={e => e.stopPropagation()}
                color="primary"
              />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              width: 250,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedValues.includes(option.value)} color="primary" />
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default CheckedMultiSelect
