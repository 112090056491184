import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing }) => ({
  // dialog: {
  //   padding: spacing(20),
  //   '& .MuiDialog-paperWidthSm': {
  //     width: spacing(400),
  //   },
  // },
  description: {
    fontSize: spacing(18),
    lineHeight: spacing(20),
    color: palette.gray[64],
    fontWeight: '400',
    textAlign: 'center',
  },
}))
