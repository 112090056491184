import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import * as Modal from '_components/modal/generic-modal'

import useFetchCall from '_hooks/use-fetch-call'

import useStyles from './styles'

const ConfirmationModal = ({
  isOpen,
  handleClose,
  description,
  cancelButtonText,
  confirmButtonText,
  handleConfirmClick,
  handleDeniedClick,
  action,
  disabled,
}) => {
  const styles = useStyles()

  const onConfirmClick = useCallback(() => {
    handleConfirmClick()
  }, [handleConfirmClick])

  const onDeniedClick = useCallback(() => {
    if (handleDeniedClick) {
      handleDeniedClick()
      return
    }

    handleClose()
  }, [handleClose, handleDeniedClick])

  const [isLoading] = useFetchCall(action, handleClose)

  return (
    <Modal.Root
      open={isOpen}
      onClose={handleClose}
      aria-describedby={`modal-${description}`}
      className={styles.dialog}
    >
      <Modal.Content>
        <Modal.InfoIcon />
        <Modal.Text id={`modal-${description}`} className={styles.description}>
          {description}
        </Modal.Text>
      </Modal.Content>
      <Modal.Actions>
        <Modal.ButtonRed onClick={onDeniedClick}>{cancelButtonText}</Modal.ButtonRed>
        <Modal.ButtonFullBlue
          onClick={onConfirmClick}
          color="primary"
          isLoading={isLoading || disabled}
        >
          {confirmButtonText}
        </Modal.ButtonFullBlue>
      </Modal.Actions>
    </Modal.Root>
  )
}

ConfirmationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  handleConfirmClick: PropTypes.func,
  isOpen: PropTypes.bool,
  action: PropTypes.string,
  handleDeniedClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  description: '',
  cancelButtonText: 'Não',
  confirmButtonText: 'Sim',
  handleConfirmClick: () => {},
  handleDeniedClick: undefined,
  isOpen: false,
  action: '',
  disabled: false,
}
export default React.memo(ConfirmationModal)
